<template>
  <span class="font-montserrat-normal text-[32px] text-cs-black dark:text-white font-bold">
    {{ getFirstText() }} <span class="text-cs-light-blue">{{ getSecondText() }}</span>
  </span>
</template>

<script lang="ts" setup>
interface ComponentProps {
  text: string
  latestWordsHighlighted?: number
}

const props = withDefaults(defineProps<ComponentProps>(), {
  latestWordsHighlighted: 1
})

const getFirstText = () => {
  const textParts = props.text.split(' ')
  return textParts.slice(0, textParts.length - props.latestWordsHighlighted).join(' ')
}

const getSecondText = () => {
  const textParts = props.text.split(' ')
  const initialPosition = textParts.length - props.latestWordsHighlighted
  const finalPosition = textParts.length

  return textParts.slice(initialPosition, finalPosition).join(' ')
}
</script>
